<template>
	<div class="login-container">
		<vue-element-loading :active="loading" :is-full-screen="true" spinner="spinner" color="#007bff" />
		<div class="head">
			<a href="https://lifehikes.com/" target="_blank" class="navbar-brand d-flex align-items-center">
				<img src="@/assets/images/LifeHikes-logo.png" width="150" height="32" />
			</a>
			<span>Portal</span>
		</div>
		<div class="go-back" @click="redirectUrl('/signup')">
			<img src="@/assets/images/goback.png" width="24" height="24" />
			<span>Back</span>
		</div>
		<b-form class="login-form" @submit.prevent="handleLogin">
			<div class="title-container">
				<div class="title">Email Verification</div>
				<div class="description">
					Please input the code that was sent to your email. Check your spam folder if you cannot find it.
				</div>
			</div>
			<div class="username-group">
				<div class="coder-group">
					<b-input class="code" v-for="(n, index) in amount" :key="index" v-model="arr[index]" autocomplete="off" maxlength="1"
					 @keydown="nextFocus($event, index)" @keyup="keyUp($event, index)" ref="numInput" />
				</div>
				<div class="verification-error" v-show="showError">
					<div class="error-icon"></div>
					<span class="error-msg">Please submit the full verification code.</span>
				</div>
			</div>
			<div class="el-pb">
				<button v-if="reDisable == true" class="btn resend-bt" @click="reSendEmail" type="button">
					Resend email
				</button>
				<button v-if="reDisable == false" class="btn resend-bt-disable" :disabled="reDisable" type="button">
					Resend email ({{ time  }}s)
				</button>
			</div>
			<div class="bt-group">
				<button :class="'btn login-bt '+(isDisable===true?'bt-disable':'btn-primary')" type="submit" ref="btlogin"
				 :disabled="isDisable">
					Confirm
				</button>
			</div>

		</b-form>
		<version-info></version-info>
	</div>
</template>

<script>
	import {
		writeGaEvent
	} from "@/utils/ga";
	import {
		showDebugLog,
		displayPopupMessage
	} from "@/utils/log";
	import {
		getCookie
	} from '@/utils/cookie'
	import {
		reSendConfirmationEmail
	} from '@/api/signup'
	import VueElementLoading from 'vue-element-loading'
	import VersionInfo from '@/views/components/VersionInfo.vue'
	export default {
		name: "EmailVerification",
		components: {
			VueElementLoading,
			VersionInfo
		},
		data() {
			return {
				amount: 6,
				arr: [],
				redirect: undefined,
				otherQuery: {},
				isDisable: true,
				reDisable: true,
				time: 0,
				loading: false,
				showError:false,
			}
		},
		created() {
			setTimeout(() => {
				this.$refs.numInput[0] && this.$refs.numInput[0].focus();
			}, 0);
			this.countdown();
		},
		mounted() {

		},
		watch: {
			arr(val) {
				this.$emit('input', val.join(''));
				if (this.arr.length === this.amount && !this.arr.includes('')) {
					this.isDisable = false;
					this.$refs.btlogin.focus();
				} else {
					this.isDisable = true;
				}
			},
			$route: {
				handler: function(route) {
					const query = route.query;
					if (query) {
						this.redirect = query.redirect;
						this.otherQuery = this.getOtherQuery(query);
					}
				},
				immediate: true
			}
		},
		destroyed() {},
		methods: {
			validateState(ref) {
				if (
					this.veeFields[ref] &&
					(this.veeFields[ref].dirty || this.veeFields[ref].validated)
				) {
					return !this.veeErrors.has(ref);
				}
				return null;
			},
			handleLogin() {
				this.$validator.validateAll().then((result) => {
					if (result) {
						this.loading = true;
						const data = {
							email: this.otherQuery.email,
							password: this.otherQuery.password,
							code: this.arr.join('')
						};
						console.info(data);
						this.$store.dispatch("user/confirmSignup", data).then((res) => {
							const src = getCookie('SRC')?? ''
							if (src) {
								window.location.href = src
							} else {
								window.location.href = process.env.VUE_APP_HOMEPAGE_URL
							}
						}).catch(err => {
							this.reDisable = false;
							showDebugLog("Signup-API-error", err);
							if (err?.response?.data?.code === 'USERS-002') {
								this.showError = true;
							} else {
								displayPopupMessage(err, "error");
							}
							writeGaEvent(this, "Signup", "confirmSignup", this.otherQuery.email, "");
						}).finally(() => {
							this.loading = false;
						});
					}
				});
			},
			getOtherQuery(query) {
				return Object.keys(query).reduce((acc, cur) => {
					if (cur !== "redirect") {
						acc[cur] = query[cur];
					}
					return acc;
				}, {});
			},
			nextFocus($event, index) {
				// 键入时光标跳转
				this.arr[index] &&
					this.$refs.numInput[index + 1] &&
					this.$refs.numInput[index + 1].focus();

				// 删除
				if ($event.keyCode === 8) {
					if (this.arr[index]) {
						this.arr[index] = '';
						this.arr = JSON.parse(JSON.stringify(this.arr));
						this.$refs.numInput[index].focus();
					} else {
						this.$refs.numInput[index - 1] &&
							this.$refs.numInput[index - 1].focus();
					}
				}

			},
			keyUp($event, index) {
				this.arr[index] = this.arr[index].replace(/[\W]/g, ''); //  /\D/g
				this.arr = JSON.parse(JSON.stringify(this.arr));
				this.showError = false;
			},
			reSendEmail() {
				this.loading = true;
				this.showError = false;
				const data = {
					email: this.otherQuery.email,
				};
				reSendConfirmationEmail(data).then((res) => {
					//this.reDisable = true;
					this.countdown();
				}).catch(err => {
					showDebugLog("Signup-API-error", err);
					displayPopupMessage(err, "error");
					writeGaEvent(this, "Signup", "reSendEmail", this.otherQuery.email, "");
				}).finally(() => {
					this.loading = false;
				});
			},
			redirectUrl(url) {
				this.$router.push({
					path: url
				});
			},
			countdown() {
				this.reDisable = false;
				this.time = 60;
				var setTimeoutS = setInterval(() => {
					this.time--;
					if (this.time <= 0) {
						clearInterval(setTimeoutS);
						this.reDisable = true;
					}
				}, 1000);

			}
		}
	}
</script>

<style lang="scss" scoped>
	$bg: #F0F6F8;
	$dark_gray: #889aa4;
	$light_gray: #eee;
	$lg-bg:#F5F5F5;

	.login-container {
		min-height: 100%;
		width: 100%;
		background: $bg url(~@/assets/images/bg.svg) 100% 100%/250px auto no-repeat;
		overflow: hidden;

		.head {
			padding: 0px 24px;
			background: #0056FF;
			height: 64px;
			display: flex;
			align-items: center;
		}

		.head span {
			margin-left: 17px;
			font-weight: 600;
			font-size: 20px;
			color: #FFFFFF;
		}
        .go-back {
			display: flex;
			height: 32px;
			padding: 20px 0px 0px 62px;
			align-items: center;
			gap: 6px;
			flex-shrink: 0;
			cursor: pointer;

			span {
				color: #3C4044;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
		.login-form {
			position: relative;
			width: 520px;
			max-width: 100%;
			padding: 0px 30px 0;
			margin: 72px auto 0px auto;
			overflow: hidden;
		}

		.title-container {
			position: relative;
			display: flex;
			flex-flow: column;
			margin-bottom: 32px;

			.title {
				font-size: 28px;
				color: #141937;
				text-align: left;
				font-weight: 600;
			}

			.description {
				font-weight: 400;
				font-size: 16px;
				color: #60666C;
			}
		}

		.username-group {
			display: flex;
			margin-bottom: 32px;
			justify-content: center;
			flex-direction: column;
			gap: 8px;
		}

		.coder-group {
			display: flex;
			justify-content: center;

		}

		.code {
			text-align: center;
			margin: 8px 0 0 8px;
			width: 51.67px;
			height: 58px;
			border-radius: 16px;
		}

		.verification-error {
			display: flex;
			align-items: center;
			gap: 8px;

			.error-icon {
				display: block;
				width: 16px;
				height: 16px;
				background: url('~@/assets/images/error.svg') no-repeat;
				background-size: 16px 16px;
				    margin-left: 13%;
			}
		}

		.error-msg {
			color: #EF483E;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.el-pb {
			margin-bottom: 24px;
			text-align: center;
		}

		.bt-group {
			display: flex;
			margin-bottom: 24px;
		}

		.goback {
			margin-right: 16px;
			height: 52px;
			width: 52px;
			display: inline-block;
			border-radius: 50%;
			padding: 12px;
		}

		.login-bt {
			width: 100%;
			border-radius: 100px;
			display: inline-block;
			padding: 16px;
			font-weight: 600;
			font-size: 16px;
			width: 100%;
			background: #0056FF !important;
		}

		.bt-disable {
			background: #BDD1E1 !important;
			border: 0;
			color: #FFFFFF;
			font-weight: 600;
			font-size: 16px;
			width: 100%;
			padding: 16px;
		}

		.resend-bt-disable {
			border-radius: 100px;
			padding: 8px 16px;
			color: #CED4D9;
			border: 1.5px solid #CED4D9;
			font-weight: 600;
			font-size: 14px;
		}

		.resend-bt {
			border-radius: 100px;
			padding: 8px 16px;
			border: 1.5px solid #CED4D9;
			font-weight: 600;
			font-size: 14px;
			color: #0056FF;
		}
	}

	@media (max-width:993px) {
		.login-container {
			background-color: $lg-bg;
		}

		.login-form {
			position: absolute !important;
			top: 30%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 520px;
			max-width: 100%;
			padding: 54px 52px 0;
			margin: 104px auto 0px auto;
			overflow: hidden;
			background-color: $lg-bg;
		}

		.left {
			display: none;
		}
	}
	@media (max-width: 412px) {
		.login-container .verification-error .error-icon{
			margin-left: 2%;
		}
	}
</style>
