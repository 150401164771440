import request from '@/utils/request'

export function login(username, password) {

  const data = {
    username,
    password
  }
  return request({
    url: '/auth',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'post',
    data
  })
}

export function globalLogout() {
  return request({
    url: '/vue-element-admin/user/logout',
    method: 'post'
  })
}

export function getInfo(token) {
  const headers = {
    'Authorization': 'Bearer ' + token
  }

  return request({
    url: '/users/me',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'get',
    headers
  })
}

export function getUserList(token, query) {
  const headers = {
    'Authorization': 'Bearer ' + token
  }

  return request({
    url: '/users',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'get',
    headers,
    params: query
  })
}

export function getUser(token, uuid) {
  const headers = {
    'Authorization': 'Bearer ' + token
  }

  return request({
    url: `/users/${uuid}`,
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'get',
    headers
  })
}

export function updateUser(token, uuid, data) {
  const headers = {
    'Authorization': 'Bearer ' + token
  }

  return request({
    url: `/users/${uuid}`,
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'patch',
    headers,
    data
  })
}

export function updateMyProfile(token, userObj) {
  const headers = {
    'Authorization': 'Bearer ' + token

  }

  return request({
    url: '/users/me',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'patch',
    headers,
    data: userObj
  })
}

export function updateUserProfile(token, userObj) {
  const headers = {
    'Authorization': 'Bearer ' + token
  }

  return request({
    url: '/users/' + userObj.uuid,
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'patch',
    headers,
    data: userObj
  })
}

export function deleteUserProfile(token, uuid) {
  const headers = {
    'Authorization': 'Bearer ' + token
  }

  return request({
    url: `/users/${uuid}/delete-permanent`,
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'post',
    headers
  })
}

export function getNoOfUsers(token, query) {
  const headers = {
    'Authorization': 'Bearer ' + token
  }

  return request({
    url: '/users/no-of-users',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'get',
    headers,
    params: query
  })
}

export function refreshCognitoToken(token) {
  const data = {
    'refreshToken': token
  }

  return request({
    url: '/auth',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'patch',
    data
  })
}

export function getBaselineStatus(token, list) {
  const headers = {
    'Authorization': 'Bearer ' + token
  }

  return request({
    url: `/users/baselineStatus`,
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'post',
    headers,
    data: list
  })
}

/**
 * Enterprise Login API (e.g. GE, META)
 * 
 */
export function loginByEnterpriseSso(data) {
  return request({
    url: `/auth/sso`,
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'post',
    data: data
  })
}

export function logOut(token) {
  const headers = {
    'Authorization': 'Bearer ' + token

  }
  return request({
    url: '/auth',
    baseURL: process.env.VUE_APP_BASE_AUTH_API,
    method: 'delete',
    headers,
  })
}