import { login, getInfo, refreshCognitoToken, loginByEnterpriseSso, logOut } from '@/api/user'
import { forceNewPassword, confirmSignup } from '@/api/signup'
import { setToken, removeToken } from '@/utils/auth'
import { getCookie, setCookie, removeCookie } from '@/utils/cookie'
import { showDebugLog } from '@/utils/log'
import default_avatar from '@/assets/images/default_avatar.png';
import router, { resetRouter } from '@/router'
import moment from 'moment-timezone'

const state = {
  uuid: '',
  email: '',
  status: '',
  token: getCookie('ACCESS_TOKEN'),
  refreshToken: getCookie('REFRESH_TOKEN'),
  name: '',
  avatar: '',
  roles: [],
  introduction: '',
  expiryTime: ''
}

const mutations = {
  SET_UUID: (state, uuid) => {
    state.uuid = uuid
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_STATUS: (state, status) => {
    state.status = status
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_REFRESH_TOKEN: (state, refreshToken) => {
    state.refreshToken = refreshToken
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = (avatar.length > 0) ? avatar : default_avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_EXPIRY_TIME: (state, time) => {
    state.expiryTime = time
  }

}

const actions = {
  // user login
  login({ commit, dispatch }, userInfo) {
    // const { username, password } = userInfo
    const username = userInfo.username.trim()
    const password = userInfo.password.trim()
    return new Promise((resolve, reject) => {
      login(username, password)
        .then(response => {
          const data = response.data
          if (data.newPasswordRequired === 'Y') {
            return resolve(data)
          }
          dispatch("setLoginInfo", data);

          resolve()
        })
        .catch(error => {
          reject(error)
          // console.log('Store-User-login-err', error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      const token = getCookie('ACCESS_TOKEN')
      // console.log('store-getInfo', token)
      getInfo(token).then(response => {
        const data = response.data

        // console.log('store-getInfo-response', data)

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { role } = data

        // roles must be a non-empty array
        if (!role || role.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        commit('SET_UUID', data.uuid)
        commit('SET_EMAIL', data.email)
        commit('SET_STATUS', data.status)
        commit('SET_NAME', data.givenName + ' ' + ((data.middleName) ? data.middleName : '') + ' ' + data.familyName)
        commit('SET_AVATAR', data.profilePic)
        commit('SET_INTRODUCTION', data.bio)
        commit('SET_ROLES', data.role)

        resolve(data)
      }).catch(error => {
        reject(error)
        // console.log('store-getInfo-err', error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    const token = getCookie('ACCESS_TOKEN');
    return new Promise((resolve, reject) => {

      logOut(token).then(response => {

        commit('SET_UUID', '')
        commit('SET_EMAIL', '')
        commit('SET_STATUS', '')
        commit('SET_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        commit('SET_NAME', '')
        commit('SET_AVATAR', '')
        commit('SET_ROLES', [])

        // set cookies
        removeCookie('ACCESS_TOKEN')
        removeCookie('REFRESH_TOKEN')
        removeCookie('UUID')
        removeCookie('ROLES')
        removeCookie('EMAIL') // reset visited views and cached views
        //dispatch('tagsView/delAllViews', null, { root: true })

      }).catch(error => {
        dispatch("resetToken");
      }).finally(() => {
        resolve()
      })
    }).catch(error => {
      dispatch("resetToken");
      reject(error)
    })
  },

  refreshToken({ commit }, rToken) {
    return new Promise((resovle, reject) => {
      // showDebugLog('store-refreshToken-param', rToken)

      refreshCognitoToken(rToken).then(response => {
        const data = response.data
        commit('SET_TOKEN', data.accessToken)
        commit('SET_EXPIRY_TIME', moment().utc().add(1, 'hours'))

        setCookie('ACCESS_TOKEN', data.accessToken)
        resovle()
      }).catch(error => {
        const msg = (typeof error.data === 'undefined') ? error : error.data.message
        showDebugLog('store-refreshToken-error', msg)
        // reject('LAP-A0001-2 : ' + 'Unknown error. Please signout and signin again.')
        reject(error)
      })
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_UUID', '')
      commit('SET_EMAIL', '')
      commit('SET_STATUS', '')
      commit('SET_TOKEN', '')
      commit('SET_REFRESH_TOKEN', '')
      commit('SET_NAME', '')
      commit('SET_AVATAR', '')
      commit('SET_ROLES', [])

      // set cookies
      removeCookie('ACCESS_TOKEN')
      removeCookie('REFRESH_TOKEN')
      removeCookie('UUID')
      removeCookie('ROLES')
      removeCookie('EMAIL')

      resolve()
    })
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async resolve => {
      const token = role + '-token'

      commit('SET_TOKEN', token)
      setToken(token)

      const { roles } = await dispatch('getInfo')

      resetRouter()

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      // reset visited views and cached views
      //dispatch('tagsView/delAllViews', null, { root: true })

      resolve()
    })
  },

  loginByEnterpriseSso({ commit, dispatch }, ssoInfo) {
    return new Promise((resolve, reject) => {
      const data = {
        client: ssoInfo.client,
        code: ssoInfo.code
      }
      loginByEnterpriseSso(data)
        .then(response => {
          const data = response.data
          dispatch("setLoginInfo", data);
          resolve()
        })
        .catch(error => {
          reject(error)
          // console.log('Store-User-login-err', error)
        })
    })
  }, forceNewPassword({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      forceNewPassword(data)
        .then(response => {
          const data = response.data
          dispatch("setLoginInfo", data);
          resolve()
        })
        .catch(error => {
          reject(error)
          // console.log('Store-User-login-err', error)
        })
    })
  }, confirmSignup({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      confirmSignup(data)
        .then(response => {
          const data = response.data
          dispatch("setLoginInfo", data);
          resolve()
        })
        .catch(error => {
          reject(error)
          // console.log('Store-User-login-err', error)
        })
    })
  }, setLoginInfo({ commit, dispatch }, data) {
    // console.log('Store-User-login-response', data)
    commit('SET_UUID', data.profile.uuid)
    commit('SET_EMAIL', data.profile.email)
    commit('SET_STATUS', data.profile.status)
    commit('SET_TOKEN', data.accessToken)
    commit('SET_REFRESH_TOKEN', data.refreshToken)
    commit('SET_NAME', data.profile.givenName + ' ' + data.profile.middleName + ' ' + data.profile.familyName)
    commit('SET_AVATAR', data.profile.profilePic)
    // commit('SET_ROLES', data.profile.role)
    commit('SET_INTRODUCTION', data.profile.bio)
    commit('SET_EXPIRY_TIME', moment().utc().add(1, 'hours'))

    showDebugLog('My Roles', data.profile.role)

    // set cookies
    setCookie('ACCESS_TOKEN', data.accessToken)
    setCookie('REFRESH_TOKEN', data.refreshToken)
    setCookie('UUID', data.profile.uuid)
    // setCookie('ROLES', data.profile.role.join('|'))
    setCookie('EMAIL', data.profile.email)

  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
