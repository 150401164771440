<template>
	<div class="login-container">
		<vue-element-loading :active="loading" :is-full-screen="true" spinner="spinner" color="#007bff" />
		<div class="head">
			<a href="https://lifehikes.com/" target="_blank" class="navbar-brand d-flex align-items-center">
				<img src="@/assets/images/LifeHikes-logo.png" width="150" height="32" />
			</a>
			<span>Portal</span>
		</div>
		<div class="go-back" @click="redirectUrl('/login')">
			<img src="@/assets/images/goback.png" width="24" height="24" />
			<span>Back</span>
		</div>
		<b-form class="login-form" :model="loginForm" @submit.prevent="handleLogin">
			<div class="title-container">
				<div class="title">Enter your work email to reset your password</div>
				<div class="description">
					A verification code will be sent to your email. If you cannot find it, please check your Spam folder.
				</div>
			</div>
			<div class="username-group">
				<b-input v-model="loginForm.email" class="username" ref="email" placeholder="Email Address " name="email address"
				 type="text" tabindex="1" autocomplete="off" v-validate="{ required: true, min: 3,email:true }" :state="validateState('email address')" />
				<b-form-invalid-feedback>{{ veeErrors.first('email address') }}</b-form-invalid-feedback>
			</div>
			<div class="bt-group">
				<b-button variant="primary" type="submit" tabindex="2" :disabled="isLogin" :class="(isLogin===true?'bt-disable':'login-bt')">
					Reset
				</b-button>
			</div>
		</b-form>
		<version-info></version-info>
	</div>
</template>

<script>
	import {
		writeGaEvent
	} from "@/utils/ga";
	import {
		showDebugLog,
		displayPopupMessage
	} from "@/utils/log";
	import {
		forgotPassword
	} from '@/api/forgotpassword'
	import VueElementLoading from 'vue-element-loading'
	import VersionInfo from '@/views/components/VersionInfo.vue'
	export default {
		name: "FogotPassword",
		components: {
			VueElementLoading,
			VersionInfo
		},
		computed: {
			isLogin() {
				let canLogin = true;
				if (this.loginForm.email) {
					canLogin = false;
				}
				return canLogin;
			}
		},
		data() {
			return {
				loginForm: {
					email: "",
				},
				redirect: undefined,
				otherQuery: {},
				loading: false,
			}
		},
		created() {

		},
		mounted() {

			if (this.loginForm.email === "") {
				this.$refs.email.focus();
			}
		},
		destroyed() {},
		methods: {
			validateState(ref) {
				if (
					this.veeFields[ref] &&
					(this.veeFields[ref].dirty || this.veeFields[ref].validated)
				) {
					return !this.veeErrors.has(ref);
				}
				return null;
			},
			handleLogin() {
				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line
						this.loading = true;
						forgotPassword(this.loginForm).then((res) => {
							this.$router.push({
								path: "/resetpassowrd",
								query: {
									email: this.loginForm.email
								}
							});
						}).catch(err => {
							showDebugLog("ForgotPassword-API-error", err);
							displayPopupMessage(err, "error");
						}).finally(() => {
							this.loading = false;
							writeGaEvent(this, "ForgotPassword", "sendEmail", this.loginForm.email, "");
						})
					}
				});
			},
			getOtherQuery(query) {
				return Object.keys(query).reduce((acc, cur) => {
					if (cur !== "redirect") {
						acc[cur] = query[cur];
					}
					return acc;
				}, {});
			},
			redirectUrl(url) {
				this.$router.push({
					path: url
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	$bg: #F0F6F8;
	$dark_gray: #889aa4;
	$light_gray: #eee;
	$lg-bg:#F5F5F5;

	.login-container {
		min-height: 100%;
		width: 100%;
		background: $bg url(~@/assets/images/bg.svg) 100% 100%/250px auto no-repeat;
		overflow: hidden;

		.head {
			padding: 0px 24px;
			background: #0056FF;
			height: 64px;
			display: flex;
			align-items: center;
		}

		.head span {
			margin-left: 17px;
			font-weight: 600;
			font-size: 20px;
			color: #FFFFFF;
		}

		.go-back {
			display: flex;
			height: 32px;
			padding: 20px 0px 0px 62px;
			align-items: center;
			gap: 6px;
			flex-shrink: 0;
			cursor: pointer;

			span {
				color: #3C4044;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}

		.login-form {
			position: relative;
			width: 520px;
			max-width: 100%;
			padding: 0px 30px 0;
			margin: 52px auto 0px auto;
			overflow: hidden;
		}

		.title-container {
			position: relative;
			display: flex;
			flex-flow: column;
			margin-bottom: 32px;

			.title {
				font-size: 28px;
				color: #141937;
				text-align: left;
				font-weight: 600;
			}

			.description {
				font-weight: 400;
				font-size: 16px;
				color: #60666C;
			}
		}

		.username-group {
			min-height: 77px;
		}

		.username {
			height: 45px;
			border-radius: 16px;
			padding: 11px 24px;
			color: #3C4044;
		}

		.form-control:focus {
			color: #495057;
			background-color: #fff;
			border-color: #80bdff;
			outline: 0;
			box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
		}

		.bt-group {
			display: flex;
		}

		.goback {
			margin-right: 16px;
			height: 52px;
			width: 52px;
			display: inline-block;
			border-radius: 50%;
			padding: 12px;
		}

		.login-bt {
			width: 100%;
			border-radius: 100px;
			padding: 16px;
			display: inline-block;
		}

		.bt-disable {
			background: #BDD1E1 !important;
			width: 100%;
			padding: 16px;
			border-radius: 100px;
			border-color: #BDD1E1 !important;
			display: inline-block;
		}

	}

	// .form-control:focus {
	// 	border: 2px solid #3C4044;
	// 	box-shadow: unset;
	// }

	// .form-control.is-invalid:focus {
	// 	border: 2px solid #dc3545;
	// 	box-shadow: unset;
	// }

	// .form-control.is-valid, .form-control.is-valid:focus {
	// 	border-color: unset;
	// 	background-image: unset;
	// 	box-shadow: unset;
	// }
</style>
