<template>
	<div class="login-container">
		 <vue-element-loading :active="loading" :is-full-screen="true" spinner="spinner"  color="#007bff" />
		<div class="head">
			<a href="https://lifehikes.com/" target="_blank" class="navbar-brand d-flex align-items-center" >
				<img src="@/assets/images/LifeHikes-logo.png" width="150" height="32" />
			</a>
			<span>Portal</span>
		</div>
		<div class="go-back" @click="redirectUrl('/forgotpassowrd')">
			<img src="@/assets/images/goback.png" width="24" height="24" />
			<span>Back</span>
		</div>
		<b-form class="login-form" :model="loginForm" @submit.prevent="handleLogin">
			<div class="title-container">
				<div class="title">Reset Password</div>
				<div class="description">
					Enter your verification code and create a new password.
				</div>
			</div>
			<div class="username-group">
				<b-input v-model="loginForm.code" class="username" ref="code" placeholder="Verification Code" name="verification code"
				 type="text" tabindex="1" autocomplete="off" v-validate="{ required: true, min: 3,}" :state="validateState('verification code')" />

				<b-form-invalid-feedback>{{ veeErrors.first('verification code') }}</b-form-invalid-feedback>
			</div>
			<div class="password-group">
				<b-form-input v-model="loginForm.password" autocomplete="off" tabindex="2" class="password" ref="password" id="password" :type="passwordType" name="password"
				 placeholder="Password" v-validate="'required|regex'" :state="validateState('password')" @keyup.enter.native="handleLogin" />
				<span class="show-pwd" @click="showPwd('password')">
					<img :src="passwordType === 'password' ? require('@/assets/images/eye-slash.png'): require('@/assets/images/eye.png')" width="24" height="24" />
				</span>
				<b-form-invalid-feedback>{{ veeErrors.first('password') }}</b-form-invalid-feedback>
			</div>
			<div class="row rule-group" v-if="loginForm.password">
				<div class="col-6 password-rule">
					<span :class="'dot '+errorClass('min')"></span><span>Min. 8 characters</span>
				</div>
				<div class="col-6 password-rule">
					<span :class="'dot '+errorClass('upCase')"></span>1 uppercase (A-Z)
				</div>
				<div class="col-6 password-rule">
					<span :class="'dot '+errorClass('lowerCase')"></span>1 lowercase (a-z)
				</div>
				<div class="col-6 password-rule">
					<span :class="'dot '+errorClass('number')"></span>1 number
				</div>
			</div>
			<div class="cfpassword-group">
				<b-form-input v-model="loginForm.repassword" autocomplete="off" tabindex="3" class="password" ref="repassword" id="re-password" :type="repasswordType"
				 name="re-password" placeholder="Re-enter Password" v-validate="{ required: true, min: 3,confirmed: 'password' }"
				 :state="validateState('re-password')" @keyup.enter.native="handleLogin" /> <span class="show-pwd" @click="showPwd('re-password')">
					<img :src="repasswordType === 'password' ? require('@/assets/images/eye-slash.png'): require('@/assets/images/eye.png')" width="24" height="24" />
				</span>
				<b-form-invalid-feedback>{{ veeErrors.first('re-password') }}</b-form-invalid-feedback>
			</div>
			<div class="bt-group">
				<b-button variant="primary" type="submit" tabindex="4"  :disabled="isLogin" :class="(isLogin===true?'bt-disable':'login-bt')" >
					Reset
				</b-button>
			</div>
		</b-form>
		<version-info></version-info>
	</div>
</template>

<script>
	import {
		writeGaEvent
	} from "@/utils/ga";
	import {
		showDebugLog,
		displayPopupMessage
	} from "@/utils/log";
	import {
		confirmForgotPassword
} from '@/api/forgotpassword'
import VueElementLoading from 'vue-element-loading'
import VersionInfo from '@/views/components/VersionInfo.vue'
	const pattern =
		/^(?=.{8,16})(?=.*[0-9])(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[!@#$%^&*~?\{\}\\\/_\>\|.,:";'-=+])[\w!@#$%^&*~?\{\}\\\/_\>\|.,:";'-=+]{8,16}$/;
	export default {
		name: "ResetPassword",
	components: { VueElementLoading,VersionInfo},
		computed: {
			isLogin() {
				let canLogin = true;
				if (this.loginForm.code && this.loginForm.password && this.loginForm.repassword)
				{
					canLogin = false;
				}
				return canLogin;
			}
		},
		data() {
			return {
				loginForm: {
					code: "",
					password: "",
					repassword: "",
				},
				redirect: undefined,
				otherQuery: {},
				passwordType: "password",
				repasswordType: 'password',
				loading:false,
			}
		},
		created() {
			this.validatorExt();
		},
		mounted() {
			if (this.loginForm.code === "") {
				this.$refs.code.focus();
			} else if (this.loginForm.password === "") {
				this.$refs.password.focus();
			} else if (this.loginForm.repassword === "") {
				this.$refs.repassword.focus();
			}
		},
		watch: {
			$route: {
				handler: function(route) {
					const query = route.query;
					if (query) {
						this.redirect = query.redirect;
						this.otherQuery = this.getOtherQuery(query);
					}
				},
				immediate: true
			}
		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)
		},
		methods: {
			validateState(ref) {
				if (
					this.veeFields[ref] &&
					(this.veeFields[ref].dirty || this.veeFields[ref].validated)
				) {
					return !this.veeErrors.has(ref);
				}
				return null;
			},
			handleLogin() {
				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line
						// this.$router.push({
						// 	path: "/login"
						// });
						const data = {
							email: this.otherQuery.email,
							code: this.loginForm.code,
							password: this.loginForm.password,
						};
						this.loading = true;
						confirmForgotPassword(data).then((res) => {
							this.$router.push({
								path: "/login",
							});
						}).catch(err => {
							showDebugLog("ForgotPassword-API-error", err);
							displayPopupMessage(err, "error");
						}).finally(() => {
							writeGaEvent(this, "ForgotPassword", "resetPassword", this.otherQuery.email, "");
							this.loading = false;
						})
					}
				});
			},
			getOtherQuery(query) {
				return Object.keys(query).reduce((acc, cur) => {
					if (cur !== "redirect") {
						acc[cur] = query[cur];
					}
					return acc;
				}, {});
			},
			showPwd(type) {
				if (type === 're-password') {
					if (this.repasswordType === "password") {
						this.repasswordType = "text";
					} else {
						this.repasswordType = "password";
					}
					this.$nextTick(() => {
						this.$refs.repassword.focus();
					});
				} else {
					if (this.passwordType === "password") {
						this.passwordType = "text";
					} else {
						this.passwordType = "password";
					}
					this.$nextTick(() => {
						this.$refs.password.focus();
					});
				}

			},
			redirectUrl(url) {
				this.$router.push({
					path: url
				});
			},
			validatorExt() {
				this.$validator.extend('regex', {
					getMessage: () => "Please enter a valid password format.",
					validate: value => pattern.test(value)
				}, {
					immediate: true
				})
			},
			errorClass(rule) {
				const password = this.loginForm.password;
				if (pattern.test(password)) {
					this.btnDisable = false;
				} else {
					this.btnDisable = true;
				}
				if (rule === 'upCase') {
					return password.match(/[A-Z]/g) === null ? '' : 'dot-success'
				} else if (rule === 'lowerCase') {
					return password.match(/[a-z]/g) === null ? '' : 'dot-success'
				} else if (rule === 'number') {
					return password.match(/[0-9]/g) === null ? '' : 'dot-success'
				} else if (rule === 'min') {
					return password.length < 8 ? '' : 'dot-success'
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	$bg: #F0F6F8;
	$dark_gray: #889aa4;
	$light_gray: #eee;
	$lg-bg:#F5F5F5;

	.login-container {
		min-height: 100%;
		width: 100%;
		background: $bg url(~@/assets/images/bg.svg) 100% 100%/250px auto no-repeat;
		overflow: hidden;

		.head {
			padding: 0px 24px;
			background: #0056FF;
			height: 64px;
			display: flex;
			align-items: center;
		}

		.head span {
			margin-left: 17px;
			font-weight: 600;
			font-size: 20px;
			color: #FFFFFF;
		}
.go-back {
			display: flex;
			height: 32px;
			padding: 20px 0px 0px 62px;
			align-items: center;
			gap: 6px;
			flex-shrink: 0;
			cursor: pointer;

			span {
				color: #3C4044;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
		.login-form {
			position: relative;
			width: 520px;
			max-width: 100%;
			padding: 0px 30px 0;
			margin: 72px auto 0px auto;
			overflow: hidden;
		}

		.title-container {
			position: relative;
			display: flex;
			flex-flow: column;
			padding-bottom: 32px;

			.title {
				font-size: 28px;
				color: #141937;
				text-align: left;
				font-weight: 600;
			}

			.description {
				font-weight: 400;
				font-size: 16px;
				color: #60666C;
			}
		}

		.username-group {
			height: 45px;
			padding-bottom: 70px;
		}

		.username {
			height: 45px;
			border-radius: 16px;
			padding: 11px 24px;
			color: #3C4044;
		}

		.password-group {
			min-height: 77px;
			position: relative;
		}

		.cfpassword-group {
			min-height: 77px;
			position: relative;
		}

		.password {
			height: 45px;
			border-radius: 16px;
			padding: 11px 24px;
			color: #3C4044
		}

		.rule-group {
			margin: 0;
		}

		.password-rule {
			display: flex;
			align-items: center;
			margin-bottom: 8px;
			padding-right: 0px;
			padding-left: 0px;
		}

		.dot {
			width: 16px;
			height: 16px;
			border-radius: 100%;
			background: #CED4D9;
			margin-right: 8px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
		}

		.dot-success {
			background: #1CCF6D;
		}

		.bt-group {
			display: flex;
		}

		.goback {
			margin-right: 16px;
			height: 52px;
			width: 52px;
			display: inline-block;
			border-radius: 50%;
			padding: 12px;
		}

		.login-bt {
			width: 100%;
			border-radius: 100px;
			padding: 16px;
			display: inline-block;
		}
        .bt-disable {
			background: #BDD1E1 !important;
			width: 100%;
			padding: 16px;
			border-radius: 100px;
			border-color:#BDD1E1 !important;
			display: inline-block;
		}
		.show-pwd {
			position: absolute;
			right: 35px;
			top: 6px;
			font-size: 20px;
			color: #3C4044;
			cursor: pointer;
			user-select: none;
		}

	}
</style>
