import request from '@/utils/request'

export function forgotPassword(data) {
    return request({
        url: `/forgot`,
        baseURL: process.env.VUE_APP_BASE_AUTH_API,
        method: 'post',
        data: data
    })
}

export function confirmForgotPassword(data) {
    return request({
        url: `/forgot`,
        baseURL: process.env.VUE_APP_BASE_AUTH_API,
        method: 'put',
        data: data
    })
}