<template>
	<div class="sys-version">
                Version: v{{ latestNoteVersion }}<br>
				©2023 Blue Planet Training, Inc. All rights reserved.
			</div>
</template>

<script>
import  releaseNote  from "@/log/release-note";
export default {
	name: 'VersionInfo',
    computed: {
    latestNoteVersion() {
      return releaseNote[0].version + " (" + process.env.VUE_APP_ENV + ")";
    }
  }
}
</script>
<style lang="scss" scoped>


.sys-version{
    width: 100%;
	text-align: center;
    color: #60666C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    margin-bottom: 40px;
     margin-top: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    position: fixed;
    bottom: 0;
}
@media (max-height:667px) {
			 .sys-version{
                 position: unset ;
             }
		}
</style>