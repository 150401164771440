import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { Base64 } from 'js-base64';
import vbMsgBox from 'bootstrap-vue-msgbox'
import VeeValidate from 'vee-validate'
import store from './store'
import router from "./router";
import DisableAutocomplete from '@/views/mixins/vue-disable-autocomplete';
import '@/styles/index.scss' // global css
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './permission' // permission control
// import VueAnalytics from 'vue-analytics'
import VueGtag from 'vue-gtag'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(vbMsgBox)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(router)
Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  // This is not required but avoids possible naming conflicts
  errorBagName: 'veeErrors'
})
Vue.use(DisableAutocomplete);
Vue.config.productionTip = false
Vue.prototype.$Base64 = Base64
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID },
  pageTrackerScreenviewEnabled: true,
  appName: 'LifeHikes SSO Portal'
}, router)
window._app = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
