import { getInitData } from '@/api/init'
const state = {
    ssoConfigs: [{ clientCode: '', clientName: '', clientWebsite: '' }]
}

const mutations = {
    SET_SSOCONFIGS: (state, ssoConfigs) => {
        state.ssoConfigs = ssoConfigs
    },

}

const actions = {
    getInitData({ commit, dispatch }, lang) {
        return new Promise((resolve, reject) => {
            getInitData(lang).then(response => {
                const data = response.data
                commit('SET_SSOCONFIGS', data.ssoConfigs)
                resolve()
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}