import Cookies from 'js-cookie'
import { getAccessTokenCookieName, getRefreshTokenCookieName } from '@lifehikes/lh-js-common-lib'
const ACCESS_TOKEN = getAccessTokenCookieName(process.env.VUE_APP_ENV);
const REFRESH_TOKEN = getRefreshTokenCookieName(process.env.VUE_APP_ENV);
const CookieKeys = [
  { key: 'ACCESS_TOKEN', value: ACCESS_TOKEN },
  { key: 'REFRESH_TOKEN', value: REFRESH_TOKEN },
  { key: 'UUID', value: 'uuid' },
  { key: 'ROLES', value: 'roles' },
  { key: 'EMAIL', value: 'email' },
  { key: 'TRAINING_ID', value: 'training-id' },
  { key: 'SRC', value: 'src' }
]

export function getCookie(cookieKey) {
  var cookie = CookieKeys.find(c => c.key === cookieKey)
  return (cookie) ? Cookies.get(cookie.value) : ''
}

export function setCookie(cookieKey, value) {

  let domain = document.domain;
  console.info("setCookie domain before：" + domain);
  const doname = /^([\w-]+\.)+((com)|(net)|(org)|(gov\.cn)|(info)|(cc)|(com\.cn)|(net\.cn)|(org\.cn)|(name)|(biz)|(tv)|(cn)|(mobi)|(name)|(sh)|(ac)|(io)|(tw)|(com\.tw)|(hk)|(com\.hk)|(ws)|(travel)|(us)|(tm)|(la)|(me\.uk)|(org\.uk)|(ltd\.uk)|(plc\.uk)|(in)|(eu)|(it)|(jp))$/;
  if (doname.test(domain))//is domian
  {
    domain = "." + domain.split('.').slice(-2).join('.');
    console.info("setCookie domain process：" + domain);
  }
  console.info("setCookie domain after：" + domain);
  var cookie = CookieKeys.find(c => c.key === cookieKey)
  console.info("setCookie cookie:" + JSON.stringify(cookie));
  if (cookieKey === "ACCESS_TOKEN" || cookieKey === "REFRESH_TOKEN") {
    console.info("setCookie ACCESS_TOKEN and REFRESH_TOKEN：" + domain);
    return (cookie) ? Cookies.set(cookie.value, value, {
      domain: domain, //这个域名填写统一登录中心的一级域名,.lifehikes.com
      path: '/'
    }) : ''
  } else {
    return (cookie) ? Cookies.set(cookie.value, value) : ''
  }
}

export function removeCookie(cookieKey) {
  let domain = document.domain;
  console.info("removeCookie domain before：" + domain);
  const doname = /^([\w-]+\.)+((com)|(net)|(org)|(gov\.cn)|(info)|(cc)|(com\.cn)|(net\.cn)|(org\.cn)|(name)|(biz)|(tv)|(cn)|(mobi)|(name)|(sh)|(ac)|(io)|(tw)|(com\.tw)|(hk)|(com\.hk)|(ws)|(travel)|(us)|(tm)|(la)|(me\.uk)|(org\.uk)|(ltd\.uk)|(plc\.uk)|(in)|(eu)|(it)|(jp))$/;
  if (doname.test(domain))//is domian
  {
    domain = "." + domain.split('.').slice(-2).join('.');
    console.info("removeCookie domain process：" + domain);
  }
  console.info("removeCookie domain after：" + domain);
  var cookie = CookieKeys.find(c => c.key === cookieKey)
  console.info("removeCookie cookie:" + JSON.stringify(cookie));
  if (cookieKey === "ACCESS_TOKEN" || cookieKey === "REFRESH_TOKEN") {
    console.info("removeCookie ACCESS_TOKEN and REFRESH_TOKEN：" + domain);
    return (cookie) ? Cookies.remove(cookie.value, {
      domain: domain, //这个域名填写统一登录中心的一级域名,.lifehikes.com
      path: '/'
    }) : ''
  } else {
    return (cookie) ? Cookies.remove(cookie.value) : ''
  }

}

