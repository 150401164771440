import request from '@/utils/request'

export function forceNewPassword(data) {
    return request({
        url: `/signup/force-new-password`,
        baseURL: process.env.VUE_APP_BASE_AUTH_API,
        method: 'post',
        data: data
    })
}
export function userSignup(data) {
    return request({
        url: `/signup/userSignup`,
        baseURL: process.env.VUE_APP_BASE_AUTH_API,
        method: 'post',
        data: data
    })
}

export function confirmSignup(data) {
    return request({
        url: `/signup/confirmSignup`,
        baseURL: process.env.VUE_APP_BASE_AUTH_API,
        method: 'put',
        data: data
    })
}

export function reSendConfirmationEmail(data) {
    return request({
        url: `/signup`,
        baseURL: process.env.VUE_APP_BASE_AUTH_API,
        method: 'patch',
        data: data
    })
}

