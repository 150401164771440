import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/',
        component: () =>
            import("../views/Login.vue"),
        meta: { title: 'Login' }
    },
    {
        path: '/login',
        component: () =>
            import("../views/Login.vue"),
        meta: { title: 'Login' }
    },
    {
        path: '/forgotpassowrd',
        component: () =>
            import("../views/FogotPassword.vue"),
        meta: { title: 'Fogot Password' }
    }, {
        path: '/resetpassowrd',
        component: () =>
            import("../views/ResetPassword.vue"),
        meta: { title: 'Reset Password' }
    }, {
        path: '/signup',
        component: () =>
            import("../views/SignUp.vue"),
        meta: { title: 'Sign Up' }
    },
    {
        path: '/emailverification',
        component: () =>
            import("../views/EmailVerification.vue"),
        meta: { title: 'Email Verification' }
    },
    {
        path: '/enterprise-login',
        component: () =>
            import("../views/EnterpriseLogin.vue"),
        meta: { title: 'Enterprise Login' }
    },
    {
        path: '/logout',
        component: () =>
            import("../views/Logout.vue"),
        meta: { title: 'Logout' }
    },
    {
        path: '/sso-login-callback',
        component: () =>
            import("../views/SSOCallBack.vue"),
        meta: { title: 'SSO CallBack' }
    }
]

const createRouter = () => new Router({
    mode: 'hash', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})


/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
]

const router = createRouter()
// console.log('router', router)
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
