<template>
	<div class="login-container" >
		<vue-element-loading :active="loading" :is-full-screen="true" size="42" spinner="spinner" color="#007bff" />
		<div class="head">
			<a href="https://lifehikes.com/" target="_blank" class="navbar-brand d-flex align-items-center">
				<img src="@/assets/images/LifeHikes-logo.png" width="150" height="32" />
			</a>
			<span>Portal</span>
		</div>
		<b-form class="login-form" :model="loginForm" @submit.prevent="handleLogin">
			<div class="title-container">
				<div class="title">Login</div>
			</div>
			<div class="username-group">
				<b-input v-model="loginForm.username" autocomplete="off"  class="username" ref="username" placeholder="Email Address " name="email address"
				 type="text" tabindex="1"  v-validate="{ required: true,email:true}" :state="validateState('email address')"
				 />
				<b-form-invalid-feedback>{{ veeErrors.first('email address') }}</b-form-invalid-feedback>
			</div>
			<div class="password-group">
				<b-form-input v-model="loginForm.password" autocomplete="off" tabindex="2" class="password" ref="password" id="password" :type="passwordType"
				 name="password" placeholder="Password"  v-validate="'required'"  :state="validateState('password')"
				 @keyup.enter.native="handleLogin" /> <span class="show-pwd" @click="showPwd">
					<img :src="passwordType === 'password' ? require('@/assets/images/eye-slash.png'): require('@/assets/images/eye.png')" width="24" height="24" />
				</span>
				<b-form-invalid-feedback>{{ veeErrors.first('password') }}</b-form-invalid-feedback>
			</div>
			<div class="remember-forgot">
				<div class="remember-me">
					<input type="checkbox" id="RememberMe" v-model="loginForm.remember" tabindex="3">
					<label for="RememberMe">&ensp;Remember me</label>
				</div>
				<a class="forgot-password" @click="redirectUrl('/forgotpassowrd')" tabindex="4">Forgot password?</a>
			</div>
			<div class="el-pb">
				<b-button variant="primary" type="submit" :disabled="isLogin" :class="(isLogin===true?'bt-disable':'login-bt')"  tabindex="5">
					Login
				</b-button>
			</div>
			<div class="el-pb">
				<div class="login-or">OR</div>
			</div>
			<div class="el-pb">
				<b-button class="login-bt-sso" @click="redirectUrl('/enterprise-login')" tabindex="6">
					Login with SSO
				</b-button>
			</div>
			<div class="el-pb">
				<span class="have-account">Don't have an account? <a class="sign-up" tabindex="7" @click="redirectUrl('/signup')">Sign
						up</a></span>
			</div>
		
		</b-form>
		<version-info></version-info>
		<b-modal ref="setPassword_modal" hide-footer no-close-on-backdrop no-close-on-esc centered>
			<b-form class="setpassword-form" ref="form"  :v-model="updatePassworldForm" @submit.stop.prevent="handleSubmit">
				<div class="title">New password required</div>
				<div class="sub-title">You must change your password before logging in.</div>
				<div class="password-group">
					<input  class="password" ref="password"   v-model="updatePassworldForm.password" id="password" :type="passwordType"
					 name="password" placeholder="Type Password Again" v-validate="'required|regex'"  :state="validateState('password')" />
					<span class="show-pwd" @click="showPwd">
						<img :src="passwordType === 'password' ? require('@/assets/images/eye-slash.png'): require('@/assets/images/eye.png')" width="24" height="24" />
					</span>
				</div>
				<div class="row rule-group">
					<div class="col-6 password-rule">
						<span :class="'dot '+errorClass('min')"></span><span>Min. 8 characters</span>
					</div>
					<div class="col-6 password-rule">
						<span :class="'dot '+errorClass('upCase')"></span>1 uppercase (A-Z)
					</div>
					<div class="col-6 password-rule">
						<span :class="'dot '+errorClass('lowerCase')"></span>1 lowercase (a-z)
					</div>
					<div class="col-6 password-rule">
						<span :class="'dot '+errorClass('number')"></span>1 number
					</div>
				</div>
				<div class="button-group">
					<b-button variant="primary" type="submit"  :class="btnDisable?'update-disabled':'update-bt'" :disabled="btnDisable">
						Confirm
					</b-button>
				</div>
			</b-form>
		</b-modal>
	</div>
</template>

<script>
	import {
		writeGaEvent
	} from "@/utils/ga";
	import {
		showDebugLog,
		displayPopupMessage
	} from "@/utils/log";
import VueElementLoading from 'vue-element-loading';
import VersionInfo from '@/views/components/VersionInfo.vue'
	import {
		getCookie,
		setCookie
	} from '@/utils/cookie'
	const pattern =
		/^(?=.{8,16})(?=.*[0-9])(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[!@#$%^&*~?\{\}\\\/_\>\|.,:";'-=+])[\w!@#$%^&*~?\{\}\\\/_\>\|.,:";'-=+]{8,16}$/;
	export default {
		name: "Login",
		components: {
			VueElementLoading,
			VersionInfo
		},
		computed: {
			isLogin() {
				let canLogin = true;
				if (this.loginForm.username && this.loginForm.password)
				{
					canLogin = false;
				}
				return canLogin;
			}
		},
		data() {
			return {
				loginForm: {
					username: "",
					password: "",
					remember: false,
				},
				redirect: undefined,
				otherQuery: {},
				passwordType: "password",
				updatePassworldForm: {
					password: "",
					email: '',
					session: '',
				},
				btnDisable: true,
				pattern: pattern,
				src: '',
				loading: false,
				
			}
		},
		created() {
			//console.info(this.$route.query.code)
			if (this.$route.query.src) {
				setCookie('SRC', this.$route.query.src)
				this.src = getCookie('SRC') ?? ''
			} else {
				this.src = getCookie('SRC') ?? ''
			}
			this.validatorExt();
		},
		mounted() {
			this.remeberMe();
			if (this.loginForm.username === "") {
				this.$refs.username.focus();
			} else if (this.loginForm.password === "") {
				this.$refs.password.focus();
			} else {
				
			}
			
		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)
		},
	methods: {
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		handleLogin() {
			this.$validator.validateAll().then((result) => {
				if (result) {
					// eslint-disable-next-line
					this.loading = true;
					if (this.loginForm.remember) {
						let password = this.$Base64.encode(this.loginForm.password); // base64加密
						localStorage.setItem("username", this.loginForm.username);
						localStorage.setItem("password", password);
					} else {
						localStorage.removeItem("username");
						localStorage.removeItem("password");
					}
					this.$store.dispatch("user/login", this.loginForm).then((res) => {
						if (res === undefined || res === null) {
							if (this.src === '') {
								window.location.href = process.env.VUE_APP_HOMEPAGE_URL
							} else {
								window.location.href = this.src;
							}

						} else {
							const data = res;
							this.updatePassworldForm.session = data.session;
							this.updatePassworldForm.email = this.loginForm.username;
							this.$refs['setPassword_modal'].show()
						}

						this.loading = false;
					}).catch(err => {
							this.loading = false;
							if (typeof err?.response?.data?.message !== 'undefined') {
								err.response.data.message = "Invalid Email or Password";
							}
							showDebugLog("Login-API-error", err);
							displayPopupMessage(err, "error");
					}).finally(() => {
							writeGaEvent(this, "Auth", "Login", this.loginForm.username, "");
					});
				}
			});
		},
		getOtherQuery(query) {
			return Object.keys(query).reduce((acc, cur) => {
				if (cur !== "redirect") {
					acc[cur] = query[cur];
				}
				return acc;
			}, {});
		},
		showPwd() {
			if (this.passwordType === "password") {
				this.passwordType = "text";
			} else {
				this.passwordType = "password";
			}
			this.$nextTick(() => {
				this.$refs.password.focus();
			});
		},
		handleSubmit() {
			this.$validator.validateAll().then((result) => {
				console.info(result)
				if (result) {
					this.loading = true;
					this.$store.dispatch("user/forceNewPassword", this.updatePassworldForm).then((res) => {
						this.$refs['setPassword_modal'].hide();
						if (this.src === '') {
							window.location.href = process.env.VUE_APP_HOMEPAGE_URL;
						} else {
							window.location.href = this.src;
						}
						this.loading = false;
					}).catch(err => {
						this.loading = false;
						showDebugLog("Login-API-error", err);
						displayPopupMessage(err, "error");
					}).finally(() => {
						writeGaEvent(this, "Auth", "ForceNewPassword", this.updatePassworldForm.email, "");
					});
				}
			});
		},
		validatorExt() {
			const dist = {
				custom: {
					'email address': {
						email: 'Please enter a valid email address.'
					},
				}
			};
			this.$validator.localize("en", dist);
			this.$validator.extend('regex', {
				getMessage: () => "Please enter a valid password format.",
				validate: value => pattern.test(value)
			}, {
				immediate: true
			})
		},
		errorClass(rule) {
			const password = this.updatePassworldForm.password;
			if (pattern.test(password)) {
				this.btnDisable = false;
			} else {
				this.btnDisable = true;
			}
			if (rule === 'upCase') {
				return password.match(/[A-Z]/g) === null ? '' : 'dot-success'
			} else if (rule === 'lowerCase') {
				return password.match(/[a-z]/g) === null ? '' : 'dot-success'
			} else if (rule === 'number') {
				return password.match(/[0-9]/g) === null ? '' : 'dot-success'
			} else if (rule === 'min') {
				return password.length < 8 ? '' : 'dot-success'
			}
		},
		redirectUrl(url) {
			this.$router.push({
				path: url
			});
		},
		remeberMe() {
			let username = localStorage.getItem("username");
			if (username) {
				this.loginForm.username = localStorage.getItem("username");
				this.loginForm.password = this.$Base64.decode(localStorage.getItem("password")); // base64解密
				this.loginForm.remember = true;
			}
		}
	}
	}
</script>

<style lang="scss" scoped>
	$bg: #F0F6F8;
	$dark_gray: #889aa4;
	$light_gray: #eee;
	$lg-bg:#F5F5F5;

	.login-container {
		min-height: 100%;
		width: 100%;
		background: $bg url(~@/assets/images/bg.svg) 100% 100%/250px auto no-repeat;
		overflow: hidden;

		.head {
			padding: 0px 24px;
			background: #0056FF;
			height: 64px;
			display: flex;
			align-items: center;
		}

		.head span {
			margin-left: 17px;
			font-weight: 600;
			font-size: 20px;
			color: #FFFFFF;
		}

		.login-form {
			position: relative;
			width: 520px;
			max-width: 100%;
			padding: 0px 30px 0;
			margin: 72px auto 0px auto;
			overflow: hidden;
		}

		.title-container {
			position: relative;
			display: flex;

			.title {
				font-size: 28px;
				color: #141937;
				text-align: left;
				font-weight: 600;
				padding-bottom: 32px;
			}
		}

		.username-group {
			height: 45px;
			padding-bottom: 70px;
		}

		.username {
			height: 45px;
			border-radius: 16px;
			padding: 11px 24px;
			color: #3C4044;
		}

		.password-group {
			height: 45px;
			padding-bottom: 70px;
			position: relative;
		}

		.password {
			height: 45px;
			border-radius: 16px;
			padding: 11px 24px;
			color: #3C4044
		}

		.remember-forgot {
			display: flex;
			padding-bottom: 24px;
		}

		.remember-me {
			color: #282B2E;
			display: flex;
			flex-grow: 1;
			align-items: center;
		}

		.remember-me label {
			margin-bottom: 0;
			font-size: 16px;
			font-weight: 400;
		}

		.remember-me input[type="checkbox"] {
			border: 1.5px solid #282B2E;
			border-radius: 2px;
			width: 16px;
			height: 16px;
		}

		.forgot-password {
			color: #282B2E;
			text-decoration: underline;
			display: flex;
			align-items: center;
			cursor: pointer;
			font-size: 16px;
			font-weight: 400;
		}

		.el-pb {
			padding-bottom: 24px;
			text-align: center;
		}

		.login-bt {
			width: 100%;
			padding: 16px;
			border-radius: 100px;
		}
        .bt-disable {
			background: #BDD1E1 !important;
			width: 100%;
			padding: 16px;
			border-radius: 100px;
			border-color:#BDD1E1 !important;
		}
		.login-or {
			font-weight: 400;
			font-size: 16px;
			text-align: center;
			color: #98A0A9;
		}

		.login-bt-sso {
			width: 100%;
			padding: 16px;
			border-radius: 100px;
			background-color: unset;
			color: #0056FF;
			border: 1px solid #ced4da;
			font-weight: 600;
			font-size: 16px;
		}

		.have-account {
			font-size: 16px;
			font-weight: 600;
			color: #60666C;
		}

		.sign-up {
			font-size: 16px;
			font-weight: bold;
			color: #282B2E;
			text-decoration: none;
			cursor: pointer;
		}

		.show-pwd {
			position: absolute;
			right: 30px;
            top: 6px;
			font-size: 20px;
			color: #3C4044;
			cursor: pointer;
			user-select: none;
			
		}
	}

	/deep/ .modal-content {
		-webkit-border-radius: 48px 16px 48px 48px !important;
		-moz-border-radius: 48px 16px 48px 48px !important;
		border-radius: 48px 16px 48px 48px !important;

	}

	/deep/ .modal-content>.modal-header {
		border-bottom: 0px !important;

	}

	/deep/ .modal-content>.modal-body>.setpassword-form {
		position: relative;
		max-width: 100%;
		padding: 0px 30px 0;
		overflow: hidden;
	}

	/deep/ .modal-content>.modal-body>.setpassword-form>.title {
		font-weight: 600;
		font-size: 20px;
		text-align: center;
		margin-bottom: 8px;
		color: #0F2C67;
	}

	/deep/ .modal-content>.modal-body>.setpassword-form>.sub-title {
		font-weight: 400;
		font-size: 14px;
		text-align: center;
		margin-bottom: 24px;
		color: #262327;
	}

	/deep/ .modal-content>.modal-body>.setpassword-form>.password-group {

		padding-bottom: 10px;
	}

	/deep/ .modal-content>.modal-body>.setpassword-form>.password-group>.password {
		height: 45px;
		border-radius: 16px;
		padding: 11px 24px;
		color: #3C4044;
		width: 100%;
		border: 1px solid #EBEEF1;
        background: #FFF;
	}

	/deep/ .modal-content>.modal-body>.setpassword-form>.password-group>.show-pwd {
		position: absolute;
		right: 65px;
		top: 89px;
		font-size: 20px;
		color: #3C4044;
		cursor: pointer;
		user-select: none;
	}

	/deep/ .modal-content>.modal-body>.setpassword-form>.button-group {
		text-align: center;
		margin-bottom: 40px;
	}

	/deep/ .modal-content>.modal-body>.setpassword-form>.button-group>.update-bt {
		border-radius: 8px;
		width: 120px;
		height: 44px;
		font-weight: 600;
		font-size: 16px;
	}

	/deep/ .modal-content>.modal-body>.setpassword-form>.button-group>.update-disabled {
		border-radius: 8px ;
		width: 120px;
		height: 44px;
		font-weight: 600;
		font-size: 16px;
		background-color: #BDD1E1 !important;
		border-color:#BDD1E1 !important;
	}

	/deep/ .rule-group {
		margin-bottom: 32px;
	}

	/deep/ .password-rule {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}

	/deep/ .dot {
		width: 16px;
		height: 16px;
		border-radius: 100%;
		background: #CED4D9;
		margin-right: 8px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
	}

	/deep/ .dot-success {
		background: #1CCF6D;
	}

	@media only screen and (max-width: 470px) {
		/deep/ .modal-content>.modal-body>.setpassword-form {
			padding: 0px 8px 0;
		}

		/deep/ .modal-content>.modal-body>.setpassword-form>.password-group>.show-pwd {
			right: 40px;
		}

	}
</style>
