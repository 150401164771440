<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
	import {
		removeCookie
	} from '@/utils/cookie'
export default {
  name: "App",
  created() {
    window.addEventListener('beforeunload', this.clearRedirectUrl);
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.clearRedirectUrl);
  }, methods: {
    clearRedirectUrl(event) {
      event.preventDefault();
      if (this.$route.path !== '/enterprise-login') {
        removeCookie('SRC');
      }
    }
  }
};
</script>
