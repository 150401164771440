module.exports = [
    {
        date: '',
        version: '1.4.0',
        description: '<ul>' +
            '<li>Meta SSO : LifeHikes SSO - Login flow</li>' +
            '</ul>'
    },
    {
        date: '2023-10-20',
        version: '1.3.0',
        description: '<ul>' +
            '<li>Amend form fields to Given name and Surname : LifeHikes SSO - Signup page</li>' +
            '</ul>'
    },
    {
        date: '2023-08-31',
        version: '1.2.0',
        description: '<ul>' +
            '<li>For all pages with back button, update and move the back button to top left corner.</li>' +
            '<li>Update the style of “Forgot Password”</li>' +
            '<li>Update the version number of LifeHikes SSO to v1.1.1/li>' +
            '</ul>'
    }, {
        date: '2023-08-17',
        version: '1.1.0',
        description: '<ul>' +
            '<li>LifeHikes SSO - Remove Our Privacy Promise in Signup page.</li>' +
            '<li>LifeHikes SSO - Update version number to v1.1.0.</li>' +
            '<li>LifeHikes SSO - Fix UI issues.</li>' +
            '</ul>'
    }, {
        date: '2023-08-11',
        version: '1.0',
        description: '<ul>' +
            '<li>LifeHikes SSO - Basic setup.</li>' +
            '<li>LifeHikes SSO - Login function with dummy UI.</li>' +
            '<li>LifeHikes SSO - Signup function with dummy UI.</li>' +
            '<li>LifeHikes SSO - Reset password with dummy UI.</li>' +
            '<li>LifeHikes SSO - First login from inhouse enrolment with dummy UI.</li>' +
            '<li>LifeHikes SSO - GE SSO Flow with dummy UI.</li>' +
            '<li>LifeHikes SSO - Apply UI to login pages.</li>' +
            '<li>LifeHikes SSO - Apply UI to signup pages.</li>' +
            '<li>LifeHikes SSO - Apply UI to login with SSO pages.</li>' +
            '<li>LifeHikes SSO - Apply UI to forgot password pages.</li>' +
            '<li>LifeHikes SSO - Redirection to source URL after login.</li>' +
            '<li>LifeHikes SSO - Logout function.</li>' +
            '<li>LifeHikes Portal - Login via LifeHikes SSO.</li>' +
            '<li>LifeHikes Portal - Logout via LifeHikes SSO.</li>' +
            '<li>LifeHikes SSO - Add Google Analytics.</li>' +
            '</ul>'
    }]