<template>
 <vue-element-loading :active="loading" :is-full-screen="true" spinner="spinner"  color="#007bff" />
</template>

<script>
	import {
		writeGaEvent
	} from "@/utils/ga";
	import {
		showDebugLog,
		displayPopupMessage
} from "@/utils/log";
import { getCookie, setCookie } from '@/utils/cookie'
import VueElementLoading from 'vue-element-loading'
	export default {
    name: "Logout",
    components: {VueElementLoading},
    data() {
        return {
            src: '',
            loading:false,
        }},
    created() {
        if (this.$route.query.src) {
            setCookie('SRC', this.$route.query.src)
            this.src = getCookie('SRC') ?? ''
        } else {
            this.src = getCookie('SRC') ?? ''
        }
        this.Logout();
    },

    methods: {
        Logout() {
            this.loading = true;
            this.$store.dispatch("user/logout").then((res) => {
                window.location.href = process.env.VUE_APP_LH_DOMAIN + (this.src ? "?src=" + this.src : "");
            }).catch(err => {
                this.loading = false;
                showDebugLog("Login-API-error", err);
                displayPopupMessage(err, "error");
            }).finally(() => {
                 writeGaEvent(this, "Auth", "Logout", "", "");
                this.loading = false;
            });
        }
    }
	}
</script>

<style lang="scss" scoped>

</style>
