<template>
	<div class="login-container">
		<vue-element-loading :active="loading" :is-full-screen="true" spinner="spinner"  color="#007bff" />
		<div class="head">
			<a href="https://lifehikes.com/" target="_blank" class="navbar-brand d-flex align-items-center">
				<img src="@/assets/images/LifeHikes-logo.png" width="150" height="32" />
			</a>
			<span>Portal</span>
		</div>
		<div class="go-back" @click="redirectUrl('/login')">
			<img src="@/assets/images/goback.png" width="24" height="24" />
			<span>Back</span>
		</div>
		<b-form class="login-form" :model="loginForm" @submit.prevent="handleLogin">
			<div class="title-container">
				<div class="title">Enterprise Login</div>
			</div>
			<div class="username-group">
				<b-input v-model="loginForm.code" class="username" ref="code" placeholder="Enterprise Code" name="enterprise code"
				 type="text" tabindex="1" autocomplete="off" v-validate="'required|regex'" :state="validateState('enterprise code')" />
				<b-form-invalid-feedback>{{ veeErrors.first('enterprise code') }}</b-form-invalid-feedback>
			</div>
			<div class="bt-group">
				<b-button type="submit" variant="primary" class="login-bt">
					Submit
				</b-button>
			</div>
		</b-form>
		<version-info></version-info>
	</div>
</template>

<script>
	import {
		writeGaEvent
	} from "@/utils/ga";
	import {
		showDebugLog,
		displayPopupMessage
	} from "@/utils/log";
import VueElementLoading from 'vue-element-loading'
import VersionInfo from '@/views/components/VersionInfo.vue'
	export default {
		name: "EnterpriseSso",
		components: {VueElementLoading,VersionInfo},
		data() {
			return {
				loginForm: {
					code: "",
				},
				redirect: undefined,
				otherQuery: {},
				ssoConfigs: [{
					clientCode: '',
					clientName: '',
					clientWebsite: ''
				}],
				loading:false,
			}
		},
	created() {
			this.loading = true;
			this.$store.dispatch("facets/getInitData", "en").then(res => {
				this.ssoConfigs = this.$store.getters.ssoConfigs
			}).finally(() => {
				this.loading = false;
				this.validatorExt();
			})
		},
		mounted() {
			if (this.loginForm.code === "") {
				this.$refs.code.focus();
			}
		},
		destroyed() {},
		methods: {
			validateState(ref) {
				if (
					this.veeFields[ref] &&
					(this.veeFields[ref].dirty || this.veeFields[ref].validated)
				) {
					return !this.veeErrors.has(ref);
				}
				return null;
			},
			handleLogin() {
				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line
						const config = this.ssoConfigs.find(p => p.clientCode.toLowerCase() === this.loginForm.code.toLowerCase());
						console.info('------config info------')
						console.info(config)
						window.location.href =config.clientWebsite;
					}
				}).finally(() => {
					writeGaEvent(this, "EnterpriseLogin", "verifySSOCode", this.loginForm.code, "");
				});
			},
			getOtherQuery(query) {
				return Object.keys(query).reduce((acc, cur) => {
					if (cur !== "redirect") {
						acc[cur] = query[cur];
					}
					return acc;
				}, {});
			},
			redirectUrl(url) {
				this.$router.push({
					path: url
				});
			},validatorExt() {
				this.$validator.extend('regex', {
					getMessage: () => "Please enter the correct verification code.",
					validate: value => {
						if (this.ssoConfigs.find(p=>p.clientCode.toLowerCase()===value.toLowerCase()))
						{
							return true;
						} else {
							return false;
						}
					}
				}, {
					immediate: true
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	$bg: #F0F6F8;
	$dark_gray: #889aa4;
	$light_gray: #eee;
	$lg-bg:#F5F5F5;

	.login-container {
		min-height: 100%;
		width: 100%;
		background: $bg url(~@/assets/images/bg.svg) 100% 100%/250px auto no-repeat;
		overflow: hidden;

		.head {
			padding: 0px 24px;
			background: #0056FF;
			height: 64px;
			display: flex;
			align-items: center;
		}

		.head span {
			margin-left: 17px;
			font-weight: 600;
			font-size: 20px;
			color: #FFFFFF;
		}
.go-back {
			display: flex;
			height: 32px;
			padding: 20px 0px 0px 62px;
			align-items: center;
			gap: 6px;
			flex-shrink: 0;
			cursor: pointer;

			span {
				color: #3C4044;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
}
		.login-form {
			position: relative;
			width: 520px;
			max-width: 100%;
			padding: 0px 30px 0;
			margin: 72px auto 0px auto;
			overflow: hidden;
		}

		.title-container {
			position: relative;
			display: flex;
			flex-flow: column;
			padding-bottom: 32px;

			.title {
				font-size: 28px;
				color: #141937;
				text-align: left;
				font-weight: 600;
			}

			.description {
				font-weight: 400;
				font-size: 16px;
				color: #60666C;
			}
		}

		.username-group {
			height: 45px;
			padding-bottom: 70px;
		}

		.username {
			height: 45px;
			border-radius: 16px;
			padding: 11px 24px;
			color: #3C4044;
		}

		.bt-group {
			display: flex;
		}

		.goback {
			margin-right: 16px;
			height: 52px;
			width: 52px;
			display: inline-block;
			border-radius: 50%;
			padding: 12px;
		}

		.login-bt {
			width: 100%;
			border-radius: 100px;
			padding: 16px;
			display: inline-block;
		}

	}
</style>
