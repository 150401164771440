<template>
 <vue-element-loading :active="loading" :is-full-screen="true" spinner="spinner"  color="#007bff" text="Loading…"/>
</template>

<script>
	import {
		writeGaEvent
	} from "@/utils/ga";
	import {
		showDebugLog,
		displayPopupMessage
} from "@/utils/log";
import { getCookie, setCookie } from '@/utils/cookie'
import VueElementLoading from 'vue-element-loading'
export default {
    name: "SSOCallBack",
    components: { VueElementLoading },
    created() {
        this.enterpriseSsoLogin();
    },
    data() {
        return {
            loading: false,
        }
    }, methods: {
        enterpriseSsoLogin() {
            const code = this.$route.query.code;
            const client = this.$route.query.client;
            if (code) {
                const data = {
                    client: client,
                    code: code
                }
                this.loading = true;
                this.$store.dispatch("user/loginByEnterpriseSso", data).then(() => {
                    const src = getCookie('SRC') ?? ''
                    if (src) {
                        window.location.href = src
                    } else {
                        window.location.href = process.env.VUE_APP_HOMEPAGE_URL
                    }
                }).catch(err => {
                    showDebugLog("Login-API-error", err);
                    displayPopupMessage(err, "error");
                }).finally(() => {
                    this.loading = false;
                    writeGaEvent(this, "Auth", "EnterpriseSsoLogin", code, "");
                });
            } else {
                 this.$router.push({
				path: '/login'
			    });
            }
        }
    }

}
</script>

<style>

</style>